<script>
import DetailSectionComponent from '@/components/VehicleCard/components/DetailSection/DetailSectionComponent';

export default {
  name: 'ExtrasComponent',

  components: {
    DetailSectionComponent,
  },

  props: {
    extras: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<template>
  <DetailSectionComponent
    v-if="extras.length"
    :title="$t('new_booking.booking_list.vehicle.specifications')"
    class="ExtrasComponent"
  >
    <div class="d-flex flex-wrap">
      <figure
        v-for="extra in extras"
        :key="extra.name"
        class="d-flex align-items-center pr-4 mb-2"
      >
        <img
          :src="extra.image"
          alt=""
          height="24"
        >
        <figcaption class="emobg-body-small emobg-color-ink-light ml-1">
          {{ extra.name }}
        </figcaption>
      </figure>
    </div>
  </DetailSectionComponent>
</template>
