<script>
export default {
  name: 'DetailSectionComponent',

  props: {
    title: {
      type: String,
      default: '',
    },
    iconProps: {
      type: Object,
      default: () => ({
        icon: undefined,
        size: undefined,
        color: undefined,
      }),
    },
  },
};
</script>

<template>
  <section>
    <header
      v-if="title"
      v-once
      class="pb-1 mb-2 emobg-border-bottom-1 emobg-border-color-ground emobg-font-default emobg-font-weight-bold emobg-color-ink"
    >
      <ui-icon
        v-if="iconProps.icon"
        v-bind="iconProps"
        class="mr-1"
      />
      {{ title }}
    </header>

    <slot />
  </section>
</template>
